import "./App.css";
import React from "react";
import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import DataCapturePage from "./Pages/DataCapturePage";
import DigitalTwinPage from "./Pages/DigitalTwinPage";

const MedicalPage = lazy(() => import("./Pages/MedicalPage"));
const EcommercePage = lazy(() => import("./Pages/EcommercePage"));
const BlogPage = lazy(() => import("./Pages/BlogPage"));
const ImplementationPage = lazy(() => import("./Pages/ImplementationPage"));
const DataOperation = lazy(() => import("./Pages/DataOperation"));
const IndexPage = lazy(() => import("./Pages/IndexPage"));
const EducationalPage = lazy(() => import("./Pages/EducationalPage"));
const IndustrialPage = lazy(() => import("./Pages/IndustrialPage"));
// const MedicalPage = lazy(() => import("./Pages/MedicalPage"));
const BankingPage = lazy(() => import("./Pages/BankingPage"));
const VisualPage = lazy(() => import("./Pages/VisualPage"));
const TeamPage = lazy(() => import("./Pages/TeamPage"));
// const OurCapabilities = lazy(() => import("./Pages/OurCapabilities"));
// const SalesPage = lazy(() => import("./Pages/SalesPage"));
// const MarketingPage = lazy(() => import("./Pages/MarketingPage"));
// const AnalyticsPage = lazy(() => import("./Pages/UserAnalyticsPage"));
// const ProdAnalyticsPage = lazy(() => import("./Pages/ProdAnalyticsPage"));
// const DataOpCapPage = lazy(() => import("./Pages/DataOpCapPage"));
// const ServiceSupportPage = lazy(() => import("./Pages/ServiceSupportPage"));
const ContactUsPage = lazy(() => import("./Pages/ContactUsPage"));
const AllBlogs = lazy(() => import("./Pages/AllBlogs"));
const Layout = lazy(() => import("./Components/Layout/Layout"));
const AriticialIntelligencePage = lazy(() =>
  import("./Pages/AriticialIntelligencePage")
);
const IotPage = lazy(() => import("./Pages/IotPage"));

function App() {
  return (
    <div className="font-archivo">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<IndexPage />} />
          <Route
            path="/ecommerce-and-retail-services"
            element={<EcommercePage />}
          />
          <Route path="/educational-services" element={<EducationalPage />} />
          <Route
            path="/manifacturing-and-industrial-services"
            element={<IndustrialPage />}
          />
          <Route
            path="/medical-and-healthcare-services"
            element={<MedicalPage />}
          />
          <Route
            path="/banking-and-financial-services"
            element={<BankingPage />}
          />
          <Route path="/data-operations" element={<DataOperation />} />
          <Route path="/implementation" element={<ImplementationPage />} />

          <Route
            path="/artificial-intelligence-and-machine-learning"
            element={<AriticialIntelligencePage />}
          />
          <Route
            path="/visualization-and-dashboarding"
            element={<VisualPage />}
          />
          <Route path="/our-team" element={<TeamPage />} />

          {/* <Route path="/our-capabilites" element={<OurCapabilities />} /> */}
          {/* <Route
            path="/our-capabilites/sales-and-business"
            element={<SalesPage />}
          />
          <Route
            path="/our-capabilites/marketing"
            element={<MarketingPage />}
          />
          <Route
            path="/our-capabilites/user-analytics"
            element={<AnalyticsPage />}
          />
          <Route
            path="/our-capabilites/product-analytics"
            element={<ProdAnalyticsPage />}
          />
          <Route
            path="/our-capabilites/data-operations"
            element={<DataOpCapPage />}
          />
          <Route
            path="/our-capabilites/services-and-support"
            element={<ServiceSupportPage />}
          /> */}
          <Route path="/data-capture" element={<DataCapturePage />} />
          <Route path="/digital-twin" element={<DigitalTwinPage />} />
          <Route path="/iot-services" element={<IotPage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:guid" element={<BlogPage />} />
          {/* <Route path="/admin" element={<Admindashboard />} /> */}
          <Route path="/blogs" element={<AllBlogs />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
