import React from "react";
import quote from "../../Assets/Components/quotes.png";

const ReviewCard = (props) => {
  return (
    <div>
      {/* css border border-gray-200  */}
      <div className="flex flex-col p-4 gap-4 rounded-xl shadow-xl my-3 w-[23rem] min-h-[14rem] relative">
        <img className="w-1/12 absolute -top-2 -left-2" src={quote} alt="" />
        <img
          className="w-1/12 absolute -bottom-2 -right-2 rotate-180"
          src={quote}
          alt=""
        />
        <div className="flex gap-4">
          <img src={props.img} alt="" />
          <div>
            <div className="font-nexa">{props.name}</div>
            <div className="font-montserrat">{props.location}</div>
          </div>
        </div>
        <p className=" line-clamp- md:line-clamp-none font-montserrat">{props.review}</p>
      </div>
    </div>
  );
};

export default ReviewCard;
